export default defineAppConfig({
  ui: {
    tooltip: {
      base: '!bg-black !text-white text-sm py-2 px-4',
      popper: { placement: 'top' }
    },
    popover: {
      base: 'bg-dark text-sm p-4'
    }
  }
})
