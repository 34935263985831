import {
  CollectionReference,
  DocumentData,
  FirestoreDataConverter,
  onSnapshot,
  Query,
  QuerySnapshot,
  Unsubscribe
} from 'firebase/firestore'
import { Controller } from '~/features/controller'
import { ControllerState } from '~/features/controllerState'
import { FirestoreType } from '../composables/useFirebase'
import { PageName } from './communicationManager'

export abstract class Service<
  DataType extends FirestoreType,
  ReturnType extends DataType | DataType[] | Record<string, DataType>
> {
  private unsubscribes: Unsubscribe[] = []
  protected cachedData: Map<number, ReturnType> = new Map()
  protected subscribers: ControllerState<ReturnType>[]
  protected activePage!: PageName
  protected lastQueries: Map<PageName, Query[]>
  protected collection: CollectionReference
  protected collectionGroup?: Query<DataType, DocumentData>
  protected collectionConstructor: <DataType extends FirestoreType>(
    collectionName: string,
    ...pathSegments: string[]
  ) => CollectionReference<DataType, DocumentData>
  private converter: FirestoreDataConverter<DataType, DocumentData>

  constructor(collectionName: string, collectionGroupName?: string) {
    this.lastQueries = new Map()

    const { collection: collectionConstructor, collectionGroup } = useFirebase()
    this.collection = collectionConstructor(collectionName)

    if (collectionGroupName) {
      this.collectionGroup = collectionGroup(collectionName)
    }

    this.collectionConstructor = collectionConstructor
    this.subscribers = []
    const { firestoreGenericConverter } = useFirebase()
    this.converter = firestoreGenericConverter<DataType>()
  }

  public subscribe(
    controller: Controller,
    sideEffect?: () => void
  ): ControllerState<ReturnType> {
    const controllerState = new ControllerState<ReturnType>(
      controller,
      sideEffect
    )
    this.subscribers.push(controllerState)
    return controllerState
  }

  public activatePage(pageName: PageName): void {
    this.activePage = pageName

    if (this.lastQueries.has(pageName)) {
      this.unsubscribeAll()

      const queries = this.lastQueries.get(pageName)!
      this.listenGroup(queries)
    }

    for (const subscriber of this.subscribers) {
      subscriber.activatePage(pageName)
    }
  }

  protected listenGroup(queries: Query[]) {
    this.lastQueries.set(this.activePage, queries)
    this.unsubscribeAll()
    this.unsubscribes = queries.map((query, index) => {
      return onSnapshot(query.withConverter(this.converter), (snapshot) =>
        /* v8 ignore next - not woth testing */
        this.updateInternalState.bind(this)(snapshot, index)
      )
    })
  }

  protected listen(query: Query): void {
    this.listenGroup([query])
  }

  protected unsubscribeAll(): void {
    for (const unsubscribe of this.unsubscribes) {
      unsubscribe()
    }

    this.cachedData.clear()
    this.unsubscribes = []
  }

  protected updateInternalState(
    snapshot: QuerySnapshot<DataType, DocumentData>,
    index: number = 0
  ): void {
    const newState = this.parseToReturnType(snapshot, index)

    const obtainedAllQueryResults =
      this.cachedData.size === this.lastQueries.get(this.activePage)?.length
    if (!obtainedAllQueryResults) {
      return
    }

    for (const subscriber of this.subscribers) {
      subscriber.setState(newState)
    }
  }

  protected abstract parseToReturnType(
    snapshot: QuerySnapshot<DataType, DocumentData>,
    index: number
  ): ReturnType
}

export class ArrayService<DataType extends FirestoreType> extends Service<
  DataType,
  DataType[]
> {
  protected parseToReturnType(
    snapshot: QuerySnapshot<DataType, DocumentData>,
    index: number
  ): DataType[] {
    const newData = []

    for (const doc of snapshot.docs) {
      newData.push(doc.data())
    }

    this.cachedData.set(index, newData)

    return Array.from(this.cachedData.values()).flat()
  }
}

export class MapService<DataType extends FirestoreType> extends Service<
  DataType,
  Record<string, DataType>
> {
  protected parseToReturnType(
    snapshot: QuerySnapshot<DataType, DocumentData>,
    index: number
  ): Record<string, DataType> {
    const newData: Record<string, DataType> = {}
    // Gather the data
    for (const doc of snapshot.docs) {
      newData[doc.id] = doc.data()
    }

    this.cachedData.set(index, newData)
    const combinedData: Record<string, DataType> = {}
    for (const data of this.cachedData.values()) {
      Object.assign(combinedData, data)
    }
    return combinedData
  }
}

export class ModelService<DataType extends FirestoreType> extends Service<
  DataType,
  DataType
> {
  protected parseToReturnType(
    snapshot: QuerySnapshot<DataType, DocumentData>,
    index: number
  ): DataType {
    const data = snapshot.docs[0].data()
    this.cachedData.set(index, data)
    return data
  }
}
