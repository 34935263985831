import { query, Timestamp, where } from 'firebase/firestore'
import { Check } from '~/models/Check'
import { ArrayService } from './service'

export class InspectionService extends ArrayService<Check> {
  public readonly cutoff = new Date().getTime() - 1000 * 3600 * 24 * 14

  constructor() {
    super('PharmacistChecks')
  }

  public listenForPharmacistChecks(): void {
    this.listen(
      query(
        this.collection,
        where('Updated', '>=', Timestamp.fromMillis(this.cutoff))
      )
    )
  }
}
