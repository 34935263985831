export enum PageName {
  BACKORDERS = 'nazendingen',
  CHECK_DELIVERY = 'controle-levering',
  CUSTOMER_PROFILE = 'klantprofiel',
  DELIVERY_OVERVIEW = 'leveringen-over',
  INDEX = 'index',
  LOAD_FILES = 'bestanden-inladen',
  LOGIN = 'inloggen',
  ORDERS = 'bestellingen',
  PARALLEL_ORDER = 'parallel-bestellen',
  PHARMACIST_INSPECTION = 'apothekers-controle',
  PRESCRIPTION_PROCESSING = 'receptverwerking',
  PRINT = 'printen',
  ACCOUNTS = 'accounts'
}

export enum UserGroups {
  Admin = 'Admin',
  Tech = 'Tech',
  Apothekers = 'Apothekers',
  Logistiek = 'Logistiek',
  Autoriseren = 'Autoriseren'
}

export enum Capability {
  PharmacistCheck_View = 'PharmacistCheck_View',
  PharmacistCheck_Judge = 'PharmacistCheck_Judge',
  PrescriptionProcessing_View = 'PrescriptionProcessing_View',
  PrescriptionProcessing_Judge = 'PrescriptionProcessing_Judge',
  Accounts_Edit = 'Accounts_Edit'
}

export type PageCapabilities = Record<Capability, UserGroups[]>

export const CAPABILITY_CONFIG = new Map<PageName, PageCapabilities>()
  .set(PageName.PHARMACIST_INSPECTION, {
    PharmacistCheck_View: [UserGroups.Apothekers],
    PharmacistCheck_Judge: [UserGroups.Apothekers]
  } as PageCapabilities)
  .set(PageName.PRESCRIPTION_PROCESSING, {
    PrescriptionProcessing_View: [UserGroups.Logistiek, UserGroups.Autoriseren],
    PrescriptionProcessing_Judge: [UserGroups.Logistiek]
  } as PageCapabilities)
  .set(PageName.ACCOUNTS, {
    Accounts_Edit: [UserGroups.Admin]
  } as PageCapabilities)
