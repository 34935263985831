<template>
  <component
    :is="to ? 'nuxt-link' : href ? 'a' : 'button'"
    :to="to"
    :href="href"
    :target="href ? '_blank' : null"
    class="btn"
    :class="[
      `btn-${variant}`,
      buttonClasses,
      { 'w-full': fullWidth },
      roundedClass,
      sizeClass
    ]"
    :disabled="loading || disabled"
    @click="emit('click', $event)"
  >
    <Spinner v-if="loading" class="mr-2 inline-block" />
    <slot />
  </component>
</template>

<script lang="ts" setup>
const props = defineProps({
  to: {
    type: String,
    default: null
  },
  href: {
    type: String,
    default: null
  },
  variant: {
    type: String,
    default: 'aubergine'
  },
  buttonClasses: {
    type: String,
    default: null
  },
  fullWidth: {
    type: Boolean,
    default: null
  },
  rounded: {
    type: String,
    default: 'full',
    validator(value: string) {
      return ['sm', 'md', 'lg', 'xl', 'full'].includes(value)
    }
  },
  size: {
    type: String,
    default: 'md',
    validator(value: string) {
      return ['sm', 'md', 'md-wide', 'lg'].includes(value)
    }
  },
  spin: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['click'])

const roundedClass = computed(() => {
  if (props.rounded) return `rounded-${props.rounded}`
})

const sizeClass = computed(() => {
  if (props.size) return `size-${props.size}`
})
</script>

<style lang="scss" scoped>
.btn {
  @apply px-8 py-2;

  &.btn-aubergine {
    @apply bg-aubergine-500 text-white;

    &:hover {
      @apply bg-aubergine-600;
    }
  }

  &.btn-signal-green-dark {
    @apply bg-signal-green-dark-500 text-white;

    &:hover {
      @apply bg-signal-green-dark-600;
    }
  }

  &.btn-aubergine-dark {
    @apply bg-aubergine-800 text-white;

    &:hover {
      @apply bg-aubergine-900;
    }
  }

  &.btn-aubergine-outline {
    @apply border-2 border-aubergine-500 text-aubergine-500;

    &:hover {
      @apply bg-aubergine-600 text-white;
    }
  }

  &.btn-aubergine-light-outline {
    @apply border border-aubergine-900 bg-aubergine-900 bg-opacity-10;

    &:hover {
      @apply bg-aubergine-600 text-white;
    }
  }

  &.btn-signal-orange {
    @apply bg-signal-orange-400 text-white;

    &:hover {
      @apply bg-signal-orange-500;
    }
  }

  &.btn-signal-rose {
    @apply bg-signal-rose-700 text-white;

    &:hover {
      @apply bg-signal-rose-800;
    }
  }

  &.btn-signal-blue {
    @apply bg-signal-blue-500 text-white;

    &:hover {
      @apply bg-signal-blue-600;
    }
  }

  &.btn-signal-blue-outline {
    @apply border-2 border-signal-blue-500 bg-white text-signal-blue-500;

    &:hover {
      @apply bg-signal-blue-500 text-white;
    }
  }

  &.btn-signal-green {
    @apply bg-signal-green-500 text-white;

    &:hover {
      @apply bg-signal-green-600;
    }
  }
  &.btn-signal-green-dark {
    @apply bg-signal-green-dark-500 text-white;

    &:hover {
      @apply bg-signal-green-dark-600;
    }
  }

  &.btn-signal-orange-outline {
    @apply border-2 border-signal-orange-500 bg-white text-signal-orange-500;

    &:hover {
      @apply bg-signal-orange-500 text-white;
    }
  }

  &.btn-light-outline {
    @apply border border-gray-200;

    &:hover {
      @apply bg-gray-300;
    }
  }

  &.btn-dark {
    @apply border bg-gray-700 text-white;

    &:hover {
      @apply bg-gray-800;
    }
  }

  &.btn-dark-outline {
    @apply border border-neutral-700 text-gray-800;

    &:hover {
      @apply bg-neutral-700 text-white;
    }
  }

  &.btn-white {
    @apply rounded-xl bg-white text-left font-bold text-aubergine-500 underline shadow-sm;

    &:hover {
      @apply bg-aubergine-500 text-white;
    }
  }

  &.size-sm {
    @apply p-4 py-1 text-sm;
  }

  &.size-md {
    @apply px-6 py-2;
  }

  &.size-md-wide {
    @apply px-8 py-1;
  }

  &.size-lg {
    @apply px-6 py-3;
  }
}

a.btn {
  @apply inline-block;

  &:hover {
    @apply cursor-pointer;
  }
}
</style>
