import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css' // if needed

export default defineNuxtPlugin((nuxtApp) => {
  const options = {
    position: 'top-right',
    timeout: 5000,
    toastDefaults: {
      error: {
        timeout: 10000,
        closeButton: false
      }
    }
  }

  nuxtApp.vueApp.use(Toast, options)
})
