<template>
  <img
    v-if="!user.hasOwnProperty('displayName')"
    :alt="$t('firebase.signInGoogle')"
    src="/images/google-signin.png"
    class="h-12 cursor-pointer"
    @click="firebaseLogin()"
  />

  <template v-else>
    <Button
      type="button"
      aria-haspopup="true"
      aria-controls="userMenu"
      class="rounded border border-solid border-gray-300 bg-white px-4 py-2 text-sm hover:bg-gray-50"
      @click="toggle"
    >
      {{ user.displayName }}
    </Button>
    <Menu
      id="userMenu"
      ref="userMenuRef"
      :model="items"
      :popup="true"
      class="text-sm"
    />
  </template>
</template>

<script lang="ts" setup>
const { firebaseLogin, user, signOutUser } = useFirebase()

const userMenuRef = ref()
const items = ref([
  {
    label: 'Uitloggen',
    command: () => signOutUser()
  }
])

const toggle = (event: any) => {
  userMenuRef.value.toggle(event)
}
</script>
