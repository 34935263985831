<template>
  <div
    class="h-screen min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8"
  >
    <div class="mx-auto max-w-max">
      <main class="sm:flex">
        <p
          class="text-4xl font-bold tracking-tight text-indigo-600 sm:text-5xl"
        >
          404
        </p>
        <div class="sm:ml-6">
          <div class="sm:border-l sm:border-gray-200 sm:pl-6">
            <h1
              class="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl"
            >
              {{ $t('error.title') }}
            </h1>
            <p class="mt-1 text-base text-gray-500">
              {{ $t('error.message') }}
            </p>
          </div>
          <div
            class="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6"
          >
            <WpButton @click="handleError()">
              {{ $t('error.goBackHome') }}
            </WpButton>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  error: Object
})
console.debug(props.error)
const handleError = () => clearError({ redirect: '/' })
</script>
