<template>
  <div class="min-h-screen bg-softlight-500">
    <AppHeader />
    <div class="mx-auto max-w-[1768px] px-5 pb-5">
      <NuxtLayout>
        <NuxtPage v-if="userGroups.length" />
      </NuxtLayout>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PageName } from './config'
import { CommunicationManager } from './services/communicationManager'

const route = useRoute()
const { userGroups } = storeToRefs(useUserStore())

onMounted(() => {
  CommunicationManager.activatePage(route.name as PageName)
})

watch(
  () => route.name,
  (value) => {
    CommunicationManager.activatePage(value as PageName)
  }
)
</script>
