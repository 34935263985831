import { and, or, query, where } from 'firebase/firestore'
import { MedicationAgreement } from '~/models'
import { MapService } from './service'

export class MedicationAgreementService extends MapService<MedicationAgreement> {
  constructor() {
    super('MedicationAgreement')
  }

  public listenForId(userId: string): void {
    const listenQuery = query(
      this.collection,
      and(
        where('UserId', '==', userId),
        or(
          where('AdminAgreementSummary.Proactive', '==', true),
          where('AdminAgreementSummary.Gds', '==', true)
        )
      )
    )
    this.listen(listenQuery)
  }
}
