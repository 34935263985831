import { query, where } from 'firebase/firestore'
import { ArrayService } from './service'
import { Task } from '~/models/Task'

export class TaskService extends ArrayService<Task> {
  constructor() {
    super('Tasks')
  }

  public listenForId(userId: string): void {
    const listenQuery = query(this.collection, where('UserId', '==', userId))
    this.listen(listenQuery)
  }
}
