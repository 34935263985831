<template>
  <Dialog
    v-model:visible="isVisible"
    modal
    :header="$t('header.accounts')"
    class="!min-w-[660px]"
  >
    <template #header>
      <div class="flex flex-row gap-2">
        <MaterialIcon
          icon="manage_accounts"
          color="aubergine-dark-500"
          class="mx-auto block"
        />{{ $t('header.accounts') }}
      </div>
    </template>

    <div class="my-6 grid grid-cols-2">
      <div class="flex flex-col">
        <span>{{ user.displayName }}</span>
        <span class="text-sm text-grey-500">{{ user.email }}</span>
      </div>
      <div>{{ userGroups.join(', ') }}</div>
    </div>

    <template #footer>
      <WpButton variant="light-outline w-full" @click.prevent="closeModal">
        {{ $t('general.close') }}
      </WpButton>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
const { user } = useFirebase()
const { userGroups } = storeToRefs(useUserStore())
const { modalVisible } = defineProps<{ modalVisible: boolean }>()
const emit = defineEmits(['closeModal'])
const isVisible = ref(modalVisible)

watch(
  () => modalVisible,
  (newValue) => {
    isVisible.value = newValue
  }
)

function closeModal() {
  emit('closeModal')
}

onUnmounted(() => {
  closeModal()
})
</script>
