import AccountCircleIcon from 'vue-material-design-icons/AccountCircle.vue'
import AlertIcon from 'vue-material-design-icons/Alert.vue'
import ArrowULeftTopIcon from 'vue-material-design-icons/ArrowULeftTop.vue'
import AutoFixIcon from 'vue-material-design-icons/AutoFix.vue'
import BarcodeIcon from 'vue-material-design-icons/Barcode.vue'
import BasketOutlineIcon from 'vue-material-design-icons/BasketOutline.vue'
import BookArrowRightIcon from 'vue-material-design-icons/BookArrowRight.vue'
import BookmarkBoxIcon from 'vue-material-design-icons/BookmarkBox.vue'
import CallMergeIcon from 'vue-material-design-icons/CallMerge.vue'
import CallSplitIcon from 'vue-material-design-icons/CallSplit.vue'
import CartIcon from 'vue-material-design-icons/Cart.vue'
import CartPlusIcon from 'vue-material-design-icons/CartPlus.vue'
import CheckboxBlankOutlineIcon from 'vue-material-design-icons/CheckboxBlankOutline.vue'
import CheckboxMarkedCircleIcon from 'vue-material-design-icons/CheckboxMarkedCircle.vue'
import CheckboxMarkedIcon from 'vue-material-design-icons/CheckboxMarked.vue'
import CheckboxOutlineIcon from 'vue-material-design-icons/CheckboxOutline.vue'
import CheckCircleIcon from 'vue-material-design-icons/CheckCircle.vue'
import CheckCircleOutlineIcon from 'vue-material-design-icons/CheckCircleOutline.vue'
import CheckIcon from 'vue-material-design-icons/Check.vue'
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue'
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue'
import ChevronUpIcon from 'vue-material-design-icons/ChevronUp.vue'
import ClockOutlineIcon from 'vue-material-design-icons/ClockOutline.vue'
import CloseBoxIcon from 'vue-material-design-icons/CloseBox.vue'
import CloseBoxOutlineIcon from 'vue-material-design-icons/CloseBoxOutline.vue'
import CloseCircleIcon from 'vue-material-design-icons/CloseCircle.vue'
import CloseCircleOutlineIcon from 'vue-material-design-icons/CloseCircleOutline.vue'
import CloseIcon from 'vue-material-design-icons/Close.vue'
import DeleteIcon from 'vue-material-design-icons/Delete.vue'
import DeleteOutlineIcon from 'vue-material-design-icons/DeleteOutline.vue'
import DotsCircleIcon from 'vue-material-design-icons/DotsCircle.vue'
import DotsVerticalIcon from 'vue-material-design-icons/DotsVertical.vue'
import FileCheckOutlineIcon from 'vue-material-design-icons/FileCheckOutline.vue'
import FileUploadIcon from 'vue-material-design-icons/FileUpload.vue'
import FormatListChecksIcon from 'vue-material-design-icons/FormatListChecks.vue'
import InformationIcon from 'vue-material-design-icons/Information.vue'
import LinkIcon from 'vue-material-design-icons/Link.vue'
import MagnifyIcon from 'vue-material-design-icons/Magnify.vue'
import PackageVariantClosedIcon from 'vue-material-design-icons/PackageVariantClosed.vue'
import PackageVariantIcon from 'vue-material-design-icons/PackageVariant.vue'
import PauseCircleIcon from 'vue-material-design-icons/PauseCircle.vue'
import PencilIcon from 'vue-material-design-icons/Pencil.vue'
import PhoneIcon from 'vue-material-design-icons/Phone.vue'
import PlusIcon from 'vue-material-design-icons/Plus.vue'
import PrinterOutlineIcon from 'vue-material-design-icons/PrinterOutline.vue'
import ProgressClockIcon from 'vue-material-design-icons/ProgressClock.vue'
import ReloadIcon from 'vue-material-design-icons/Reload.vue'
import SnowflakeIcon from 'vue-material-design-icons/Snowflake.vue'
import SnowflakeOffIcon from 'vue-material-design-icons/SnowflakeOff.vue'
import SpeedometerIcon from 'vue-material-design-icons/Speedometer.vue'
import StarIcon from 'vue-material-design-icons/Star.vue'
import TableIcon from 'vue-material-design-icons/Table.vue'
import ThermometerPlusIcon from 'vue-material-design-icons/ThermometerPlus.vue'
import UndoVariantIcon from 'vue-material-design-icons/UndoVariant.vue'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('AccountCircleIcon', AccountCircleIcon)
  nuxtApp.vueApp.component('AlertIcon', AlertIcon)
  nuxtApp.vueApp.component('ArrowULeftTopIcon', ArrowULeftTopIcon)
  nuxtApp.vueApp.component('AutoFixIcon', AutoFixIcon)
  nuxtApp.vueApp.component('BarcodeIcon', BarcodeIcon)
  nuxtApp.vueApp.component('BasketOutlineIcon', BasketOutlineIcon)
  nuxtApp.vueApp.component('BookArrowRightIcon', BookArrowRightIcon)
  nuxtApp.vueApp.component('BookmarkBoxIcon', BookmarkBoxIcon)
  nuxtApp.vueApp.component('CallMergeIcon', CallMergeIcon)
  nuxtApp.vueApp.component('CallSplitIcon', CallSplitIcon)
  nuxtApp.vueApp.component('CartIcon', CartIcon)
  nuxtApp.vueApp.component('CartPlusIcon', CartPlusIcon)
  nuxtApp.vueApp.component('CheckboxBlankOutlineIcon', CheckboxBlankOutlineIcon)
  nuxtApp.vueApp.component('CheckboxMarkedCircleIcon', CheckboxMarkedCircleIcon)
  nuxtApp.vueApp.component('CheckboxMarkedIcon', CheckboxMarkedIcon)
  nuxtApp.vueApp.component('CheckboxOutlineIcon', CheckboxOutlineIcon)
  nuxtApp.vueApp.component('CheckCircleIcon', CheckCircleIcon)
  nuxtApp.vueApp.component('CheckCircleOutlineIcon', CheckCircleOutlineIcon)
  nuxtApp.vueApp.component('CheckIcon', CheckIcon)
  nuxtApp.vueApp.component('ChevronDownIcon', ChevronDownIcon)
  nuxtApp.vueApp.component('ChevronRightIcon', ChevronRightIcon)
  nuxtApp.vueApp.component('ChevronUpIcon', ChevronUpIcon)
  nuxtApp.vueApp.component('ClockOutlineIcon', ClockOutlineIcon)
  nuxtApp.vueApp.component('CloseBoxIcon', CloseBoxIcon)
  nuxtApp.vueApp.component('CloseBoxOutlineIcon', CloseBoxOutlineIcon)
  nuxtApp.vueApp.component('CloseCircleIcon', CloseCircleIcon)
  nuxtApp.vueApp.component('CloseCircleOutlineIcon', CloseCircleOutlineIcon)
  nuxtApp.vueApp.component('CloseIcon', CloseIcon)
  nuxtApp.vueApp.component('DeleteIcon', DeleteIcon)
  nuxtApp.vueApp.component('DeleteOutlineIcon', DeleteOutlineIcon)
  nuxtApp.vueApp.component('DotsCircleIcon', DotsCircleIcon)
  nuxtApp.vueApp.component('DotsVerticalIcon', DotsVerticalIcon)
  nuxtApp.vueApp.component('FileCheckOutlineIcon', FileCheckOutlineIcon)
  nuxtApp.vueApp.component('FileUploadIcon', FileUploadIcon)
  nuxtApp.vueApp.component('FormatListChecksIcon', FormatListChecksIcon)
  nuxtApp.vueApp.component('InformationIcon', InformationIcon)
  nuxtApp.vueApp.component('LinkIcon', LinkIcon)
  nuxtApp.vueApp.component('MagnifyIcon', MagnifyIcon)
  nuxtApp.vueApp.component('PackageVariantClosedIcon', PackageVariantClosedIcon)
  nuxtApp.vueApp.component('PackageVariantIcon', PackageVariantIcon)
  nuxtApp.vueApp.component('PauseCircleIcon', PauseCircleIcon)
  nuxtApp.vueApp.component('PencilIcon', PencilIcon)
  nuxtApp.vueApp.component('PhoneIcon', PhoneIcon)
  nuxtApp.vueApp.component('PlusIcon', PlusIcon)
  nuxtApp.vueApp.component('PrinterOutlineIcon', PrinterOutlineIcon)
  nuxtApp.vueApp.component('ProgressClockIcon', ProgressClockIcon)
  nuxtApp.vueApp.component('ReloadIcon', ReloadIcon)
  nuxtApp.vueApp.component('SnowflakeIcon', SnowflakeIcon)
  nuxtApp.vueApp.component('SnowflakeOffIcon', SnowflakeOffIcon)
  nuxtApp.vueApp.component('SpeedometerIcon', SpeedometerIcon)
  nuxtApp.vueApp.component('StarIcon', StarIcon)
  nuxtApp.vueApp.component('TableIcon', TableIcon)
  nuxtApp.vueApp.component('ThermometerPlusIcon', ThermometerPlusIcon)
  nuxtApp.vueApp.component('UndoVariantIcon', UndoVariantIcon)
})
