import type { AppUser, Parcel } from '~/models'

export const useSearchParcel = defineStore('searchParcel', () => {
  const appUsers = ref<AppUser[]>([])
  const appUserActive = ref<AppUser | null>(null)
  const loadingParcelsOpen = ref(false)
  const loadingParcelsCompleted = ref(false)
  const highlightParcel = ref('')
  const searchQuery = ref('')
  const parcelsOpen = ref<Parcel[]>([])
  const parcelsCompleted = ref<Parcel[]>([])
  const showAddParcelForm = ref(false)

  function $resetSearchParcel() {
    appUsers.value = []
    appUserActive.value = null
    parcelsOpen.value = []
    parcelsCompleted.value = []
    showAddParcelForm.value = false
  }

  return {
    $resetSearchParcel,
    appUserActive,
    appUsers,
    highlightParcel,
    loadingParcelsCompleted,
    loadingParcelsOpen,
    parcelsCompleted,
    parcelsOpen,
    searchQuery,
    showAddParcelForm
  }
})
