import { documentId, query, where } from 'firebase/firestore'
import { AproData } from '~/models'
import { ArrayService } from './service'

export class AproDataService extends ArrayService<AproData> {
  constructor() {
    super('AppUsers')
  }

  public listenForId(userId: string): void {
    this.collection = this.collectionConstructor(
      'AppUsers',
      userId,
      'ExternalUserData'
    )

    const listenQuery = query(
      this.collection,
      where(documentId(), '==', 'AproData')
    )
    this.listen(listenQuery)
  }
}
