import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()
  const { vueApp } = nuxtApp

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: runtimeConfig.public.SENTRY_DSN || '',
    environment: 'production',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    attachStacktrace: true,
    autoSessionTracking: true,
    logErrors: true,
    tracingOptions: {
      hooks: ['mount', 'update', 'create'],
      trackComponents: true
    },
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        console.error(
          `[Exeption handled by Sentry]: (${hint.originalException})`,
          { event, hint }
        )
      }
      // Continue sending to Sentry
      return event
    }
  })

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update']
    })
  )
  Sentry.attachErrorHandler(vueApp, {
    logErrors: true,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update']
  })

  nuxtApp.provide('sentry', Sentry)

  return {
    provide: {
      $sentry: Sentry
    }
  }
})
