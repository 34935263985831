import * as Sentry from '@sentry/vue'

export default function useSentry() {
  function reportError(
    errorMessage: string,
    contextVariables: Record<string, unknown>
  ) {
    Sentry.captureException(new Error(errorMessage), {
      extra: contextVariables
    })
  }

  return { reportError }
}
