import { storeToRefs } from 'pinia'
import { Capability, CAPABILITY_CONFIG, PageName, UserGroups } from '../config'

export class InvalidConfigException extends Error {}

export type CapabilityChecker = (
  pageName: PageName,
  capability: Capability,
  selectedAccountGroups?: UserGroups[]
) => boolean

export default function useRights() {
  const { auth, userGroups, isUserAdmin, overwrittenUserGroups } =
    storeToRefs(useUserStore())

  /**
   * Check if the current user has the required capability.
   *
   * This function throws an error if the page or capability is not configured.
   */
  function hasCapability(
    pageName: PageName,
    capability: Capability,
    selectedAccountGroups?: UserGroups[]
  ): boolean {
    let groups = userGroups.value
    if (selectedAccountGroups !== undefined) {
      groups = selectedAccountGroups
    }

    const capabilityGroups = getCapabilityGroups(pageName, capability)
    return userMatchesCapabilityGroup(groups, capabilityGroups)
  }

  /**
   * Check if the current user can access a certain page.
   */
  function canAccessPage(
    pageName: PageName,
    selectedAccountGroups?: UserGroups[]
  ): boolean {
    const pageCapabilities = CAPABILITY_CONFIG.get(pageName)
    if (pageCapabilities === undefined) {
      return false
    }
    const groups = selectedAccountGroups ?? userGroups.value
    for (const capabilityGroups of Object.values(pageCapabilities)) {
      if (userMatchesCapabilityGroup(groups, capabilityGroups)) {
        return true
      }
    }
    return false
  }

  /**
   * Override the user group for the current user.
   *
   * This is an admin-only functionality.
   */
  function overrideUserGroup(newUserGroups: UserGroups[]): void {
    if (isUserAdmin.value === false) {
      return
    }

    overwrittenUserGroups.value = newUserGroups
    userGroups.value = newUserGroups
  }

  /**
   * Override the user group for the current user.
   *
   * This is an admin-only functionality.
   */
  async function resetOverride(): Promise<void> {
    overwrittenUserGroups.value = []
    const tokenResult = await auth.value?.currentUser?.getIdTokenResult()
    userGroups.value = tokenResult?.claims['userGroups'] as UserGroups[]
  }

  return {
    hasCapability,
    canAccessPage,
    overrideUserGroup,
    resetOverride
  }
}

function getCapabilityGroups(
  pageName: PageName,
  capability: Capability
): UserGroups[] {
  const pageCapabilities = CAPABILITY_CONFIG.get(pageName)
  if (pageCapabilities === undefined) {
    throw new InvalidConfigException(
      `No configuration found for page ${pageName}`
    )
  }
  const capabilityGroups = pageCapabilities[capability]
  if (capabilityGroups === undefined) {
    throw new InvalidConfigException(
      `No configuration found for capability ${capability}`
    )
  }
  return capabilityGroups
}

function userMatchesCapabilityGroup(
  userGroups: UserGroups[],
  capabilityGroups: UserGroups[]
): boolean {
  if (userGroups.includes(UserGroups.Admin)) {
    return true
  }
  for (const userGroup of userGroups) {
    if (capabilityGroups.includes(userGroup)) {
      return true
    }
  }
  return false
}
