export default function useApi() {
  const config = useRuntimeConfig()
  const { auth, isUserLoggedIn } = storeToRefs(useUserStore())

  async function apiGet<T>(
    endpoint: ApiGatewayUrl,
    { query, headers = {} }: GetFetchOptions,
    endpointContext?: EndpointContext
  ): Promise<T> {
    let url = getLocalOrGatewayUrl(endpoint)

    if (endpointContext) {
      url = fillRouteTemplate(url, endpointContext)
    }

    return await $fetch<T>(url, {
      headers: { ...(await getBearerTokenHeader()), ...headers },
      query
    })
  }

  async function apiPost<T>(
    endpoint: ApiGatewayUrl,
    { query, body, headers = {} }: PostFetchOptions,
    endpointContext?: EndpointContext
  ) {
    let url = getLocalOrGatewayUrl(endpoint)

    if (endpointContext) {
      url = fillRouteTemplate(url, endpointContext)
    }

    return await $fetch<T>(url, {
      method: 'POST',
      body: body,
      headers: { ...(await getBearerTokenHeader()), ...headers },
      query
    })
  }

  async function getBearerTokenHeader(): Promise<{ Authorization?: string }> {
    if (!isUserLoggedIn.value) {
      await AuthInitialized()
    }

    const accessToken = await auth.value?.currentUser?.getIdToken()

    if (!accessToken) {
      return {}
    }

    return {
      Authorization: `Bearer ${accessToken}`
    }
  }

  async function AuthInitialized() {
    await new Promise<void>((resolve) => {
      const unwatch = watch(
        () => isUserLoggedIn.value,
        (newValue: boolean) => {
          if (newValue) {
            unwatch()
            resolve()
          }
        }
      )
    })
  }

  /**
   * When mais is deployed, we use a google cloud api gateway to get access to the back-end
   * When we develop locally, we do not run an api gateway (in between the front- and back-end) and call the cloud functions directly
   */
  function getLocalOrGatewayUrl(apiGatewayUrl: ApiGatewayUrl) {
    if (config.public.IS_USING_LOCAL_BACKEND) {
      return `${config.public.CF_URL}/Deliveries-${gatewayUrlToFunctionName[apiGatewayUrl]}`
    }

    return `${config.public.CF_GATEWAY_URL}/${apiGatewayUrl}`
  }

  function fillRouteTemplate(url: string, context: EndpointContext): string {
    return url.replace(/{(\w+)}/g, (_, key) => context[key] || '')
  }

  return { apiGet, apiPost, getLocalOrGatewayUrl }
}

export interface FetchOptionsBase {
  query?: Record<string, any>
  headers?: HeadersInit
}

export interface PostFetchOptions extends FetchOptionsBase {
  body?: any
}

export interface GetFetchOptions extends FetchOptionsBase {}

interface EndpointContext {
  [key: string]: string
}

export type ApiGatewayUrl =
  | 'amp/create'
  | 'user/authenticate'
  | 'zoho/call-api'
  | 'amp/cancel'
  | 'bucket/download'
  | 'bucket/metadata'
  | 'bucket/upload'
  | 'notificare/call-api'
  | 'parallel-order/folders'
  | 'parallel-order/folder/files'
  | 'parallel-order/start-job'
  | 'amp/reprint'
  | 'shipping/{provider}/cancel/{shipmentId}'
  | 'shipping/{provider}/create'
  | 'shipping/{provider}/pickuppoint-active/{shipmentId}'
  | 'shipping/{provider}/reprint/{shipmentId}'
  | 'jspm/validate-licence'
  | 'checks/'
  | 'domain-event/{topic}/{event}'
  | 'accounts/'
  | 'standards/wcia'

const gatewayUrlToFunctionName: Record<ApiGatewayUrl, string> = {
  'accounts/': 'httpsAccounts',
  'amp/create': 'httpsAMPCreateShipment',
  'user/authenticate': 'httpsAuthenticateUser',
  'zoho/call-api': 'httpsCallZohoApi',
  'amp/cancel': 'httpsCancelAMPLabel',
  'bucket/download': 'httpsGoogleCloudManager/download',
  'bucket/metadata': 'httpsGoogleCloudManager/metadata',
  'bucket/upload': 'httpsGoogleCloudManager/upload',
  'notificare/call-api': 'httpsNotificare',
  'parallel-order/folders':
    'httpsParallelOrderManager/getFoldersFromGoogleCloudBucket',
  'parallel-order/folder/files':
    'httpsParallelOrderManager/downloadFilesInFolderFromGoogleCloudBucket',
  'parallel-order/start-job': 'httpsParallelOrderManager/startParallelOrderJob',
  'amp/reprint': 'httpsReprintAMPLabel',
  'shipping/{provider}/cancel/{shipmentId}':
    'httpsShippingManagement/{provider}/cancel/{shipmentId}',
  'shipping/{provider}/create': 'httpsShippingManagement/{provider}/create',
  'shipping/{provider}/pickuppoint-active/{shipmentId}':
    'httpsShippingManagement/{provider}/pickuppoint-active/{shipmentId}',
  'shipping/{provider}/reprint/{shipmentId}':
    'httpsShippingManagement/{provider}/reprint/{shipmentId}',
  'jspm/validate-licence': 'httpsValidateJSPMLicense',
  'checks/': 'httpsPharmasistChecks/checks',
  'domain-event/{topic}/{event}':
    'httpsSendDomainEvent/domain-event/{topic}/{event}',
  'standards/wcia': 'httpsStandards/wcia'
}
